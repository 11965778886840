
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './redux/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoadingComponent, RouteGuardComponent as RouteGuard, AlertComponent, ConfirmComponent } from 'shared/components';

import { MainLayout } from './layouts/MainLayout';
import { HomeLayout } from './layouts/HomeLayout';
import OauthCallback from './modules/oauth-callback/OauthCallback';
import { LoginComponent } from './modules/login';
import { LoginSSOComponent } from './modules/loginsso';
import { UnauthorizedComponent } from './modules/Unauthorized';
import { LogoutComponent } from './modules/logout';
import ToastComponent from 'shared/components/behaviors/ToastComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import hotkeys from 'hotkeys-js';

class App extends React.Component {
    componentDidMount() {        
        hotkeys('alt+s', function (event, handler) {
            // Prevent the default refresh event under WINDOWS system
            event.preventDefault();
            // if (document.getElementById("hoanthanhBtn"))
            //     document.getElementById("hoanthanhBtn").click();
            console.log('alt+s');
        });
    }
   
    componentWillUnmount() {
      
    }
    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={<LoadingComponent loading={true} />} persistor={persistor}>
                    <Router>
                        <Switch>
                            
                            <Route path="/login" component={LoginComponent} />
                            <Route path="/logout" component={LogoutComponent} />                            
                            <Route path="/" component={HomeLayout} />
                            <RouteGuard component={MainLayout} />

                        </Switch>
                    </Router>
                    <ToastComponent />
                    <ToastContainer position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover />
                    <AlertComponent />
                    <ConfirmComponent />
                </PersistGate>
            </Provider>
        );
    }
}
export default App;