import React from 'react';
class OauthCallback extends React.Component {
    
    
    render() {
        return (
            <h1>OauthCallback Code</h1>
        );
    }
}

export default OauthCallback;