import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoadingComponent } from "shared/components";
import { authService } from "shared/services";
import { _HomeService } from "modules/home/HomeService";
import Dropdown from "react-bootstrap/Dropdown";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      dataLienKets:[],
    };
    this.subscriptions = {};
  }
  logout() {
    authService.logout();
    window.location.href = "/login";
    // if (window.location.pathname != "/login")
    //     window.location.href = '/login';
  }

  componentDidMount() {
    //console.log(this.props)
    this.setState({ loading: true });
    _HomeService.getTopHead().subscribe((res) => {
      this.setState({ data: res.data, loading: false });
    });
    _HomeService.getDataLienKet().subscribe((res) => {
      this.setState({ dataLienKets: res.data });
    });
  }
  render() {
    let { data } = this.state;
    let user = this.props.user;
    return (
      <React.Fragment>
        {data && (
          <header id="header">
            <div className="container header">
              <div className="row">
                <div className="col-md-6 col-12 left-header">
                  <button
                    className="btn btn-default dropdown-toggle text-white"
                    type="button"
                    id="menu1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fa fa-phone text-whites"/> HOTLINE CÁC ĐƠN VỊ LIÊN KẾT
                    <span className="mr-1 caret text-white" />
                  </button>
                  <div
                    className="dropdown-menu m-0"
                    aria-labelledby="navbarDropdown"
                    x-placement="bottom-start"
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      top: 0,
                      left: 0,
                      transform: "translate3d(255px, 41px, 0px)",
                      minWidth:'350px',
                      maxHeight:'500px',
                      overflow:'auto',
                    }}
                  >
                    {
                      this.state.dataLienKets?.map((item)=>{
                        return (<a
                          className="dropdown-item"
                          href="#"
                        >
                          <button type="button" className="btn btn-sm btn-primary mr-2">{item.name} </button> {item.phone}
                        </a>)
                      })
                    }
                  </div>
                </div>
                <div className="col-md-6 col-12 right-header">
                  <span className="text-white font-16">
                    <a className="" target="_blank" href={data?.youtube}>
                      <i
                        style={{ color: "#fff", position: "relative", top: 2 }}
                        className="fab fa-youtube"
                      />
                    </a>{" "}
                    |{" "}
                  </span>
                  <span className="text-white font-16">
                    <a className="" target="_blank" href={data?.fangage}>
                      <i
                        style={{ color: "#fff", position: "relative", top: 2 }}
                        className="fab fa-facebook-square"
                      />
                    </a>{" "}
                    |{" "}
                  </span>
                  {user.data && user.access_token ? (
                    <>
                      <button
                        className="btn btn-default dropdown-toggle "
                        type="button"
                        id="menu1"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="text-white">
                          <i className="fa fa-user" /> {user.data?.email}
                        </span>
                        <span className="caret text-white" />
                      </button>
                      <div
                        className="dropdown-menu m-0 account"
                        aria-labelledby="navbarDropdown"
                        x-placement="bottom-start"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: 0,
                          left: 0,
                          transform: "translate3d(255px, 41px, 0px)",
                        }}
                      >
                        <a
                          className="dropdown-item"
                          href="/tai-khoan"
                          title="Tài khoản"
                        >
                          Tài khoản
                        </a>
                        <a
                          className="dropdown-item"
                          href="/ho-so"
                          title="Tài khoản"
                        >
                          Hồ sơ
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.logout}
                          title="Đăng xuất"
                        >
                          Đăng xuất
                        </a>
                      </div>
                    </>
                  ) : (
                    <span className="text-white">
                      <a style={{ color: "#fff" }} href="/login">
                        <i className="fa fa-user" /> Đăng nhập
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </header>
        )}

        <LoadingComponent loading={this.state.loading}></LoadingComponent>
      </React.Fragment>
    );
  }
}
Header.propTypes = {
  user: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    user: state.oauth,
  };
};
const HeaderComponent = connect(mapStateToProps, {})(Header);
export { HeaderComponent };
